import React from 'react';
import { Button } from 'primereact/button';
import { FcaRmarPresetOptions } from "@/Enums/FcaRmarPresetOptions";

export type FcaRmarFilterPresetsListProps = {
    value?: FcaRmarPresetOptions
    onChange: (presetName: FcaRmarPresetOptions) => void
};
const availableFcaRmarPresets: FcaRmarPresetOptions[] = [
    FcaRmarPresetOptions['H1-2024'],
    FcaRmarPresetOptions['H2-2024'],
    FcaRmarPresetOptions['H1-2025']
];

const FcaRmarFilterPresetsList: React.FC<FcaRmarFilterPresetsListProps> = ({
    value,
    onChange
}: FcaRmarFilterPresetsListProps) => {

    return (
        <div className="flex gap-2 justify-content-center">
            {
                availableFcaRmarPresets.map(
                    presetName => <Button
                        key={presetName}
                        label={presetName}
                        onClick={() => {
                            onChange?.(presetName);
                        }}
                    />
                )
            }
        </div>
    );
};

export default FcaRmarFilterPresetsList;
