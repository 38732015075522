import React, { useEffect, useMemo, useState } from 'react';
import '../styles/datatables.scss';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { GetFcaRmarReportRequest } from "@/stub";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import styled from "styled-components";
import FcaRmarDatatable from "@/components/Datatables/FcaRmarDatatable";
import { Button } from "primereact/button";
import FcaRmarFilterPresetsList from "@/components/FcaRmar/FcaRmarFilterPresetsList";
import { FcaRmarPresetOptions } from "@/Enums/FcaRmarPresetOptions";
import { yearStart } from "@formkit/tempo";
import { useGetFcaRmarPdf } from "@/Service/Api/ApiHooks/Reports/useGetFcaRmarPdf";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { downloadBlob } from "@/Util/downloadBlob";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

const defaultInterval = [
    yearStart(new Date()),
    new Date(new Date().getFullYear(), 5, 30),
];

const getIntervalForPreset = (preset: FcaRmarPresetOptions) => {
    if (preset === FcaRmarPresetOptions['H1-2024']) {
        return [
            yearStart(new Date(2024)),
            new Date(2024, 5, 30),
        ]
    } else if (preset === FcaRmarPresetOptions['H2-2024']) {
        return [
            new Date(2024, 6, 1),
            new Date(2024, 12, 31),
        ]
    } else if (preset === FcaRmarPresetOptions['H1-2025']) {
        return [
            yearStart(new Date(2025)),
            new Date(2025, 5, 30),
        ]
    }
}

/**
 * FCA RMAR page
 */
const FcarRmarPage: React.FC = () => {
    const [dateRangeFilter, setDateRangeFilter] = useState<Date[]>(getIntervalForPreset(FcaRmarPresetOptions['H1-2024']));
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const {
        isPending,
        mutateAsync,
    } = useGetFcaRmarPdf();

    const onDownload = async (preset: FcaRmarPresetOptions) => {
        const dateRange = getIntervalForPreset(preset);
        setDateRangeFilter(dateRange);

        const requestParams: GetFcaRmarReportRequest = {
            from: formatToApiDate(dateRange?.[0]) ?? undefined,
            to: formatToApiDate(dateRange?.[1]) ?? undefined,
        }

        await mutateAsync(requestParams, {
            onSuccess: (data) => {
                downloadBlob(data, 'FCA RMAR Report');
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title='Compliance Centre'
                    description='Financial Conduct Authority RMAR Report'
                />
                <div className="content-container">
                    <div className="page-actions">
                        <p>Choose period:</p>
                        <div className="flex justify-content-between">
                            <FcaRmarFilterPresetsList
                                onChange={(presetName) => {
                                    onDownload(presetName);
                                }}
                            />
                        </div>
                    </div>
                    {/*<FcaRmarDatatable*/}
                    {/*    requestParams={requestParams}*/}
                    {/*/>*/}
                </div>
            </StyledWrap>
        </div>
    );
};

export default withAuthenticationRequired(FcarRmarPage);
